<template>
  <es-table class="pr-analytics" :headers="headers" :items="analytics" date-filter download="analytics.export"
            @downloadData="downloadData" @update="getPaymentsAnalytics" :importFormats="importFormats">
    <div class="d-flex align-center">
      <h3 class="mr-7">Отчеты по оплате</h3>
      <EsFastDatesRange />
    </div>
  </es-table>
</template>

<script>
import {mapActions, mapState} from "vuex";
import exportData from '@/utils/files/requestExport';
import EsFastDatesRange from "@/components/сommon/EsTable/EsFastDatesRange";

export default {
  name: "PaymentReportsAnalytics",
  components: {EsFastDatesRange},
  data: () => ({
    importFormats: ['xlsx'],
    headers: [
      {
        text: 'Клиент',
        value: 'client_name',
      },
      {
        text: 'Сумма оплаты',
        value: 'sum',
      },
      {
        text: 'Дата оплаты',
        value: 'payment_date',
      },
      {
        text: 'Сделка',
        value: 'payment_deal_name',
      },
    ],
  }),
  computed: {
    ...mapState("analytics", ["analytics",]),
  },
  methods: {
    ...mapActions({
      getPaymentsAnalytics: 'analytics/getPaymentsAnalytics',
    }),
    downloadData(payload) {
      exportData(this.$store.dispatch, 'analytics/downloadReport', {
        name: "payments",
        args: payload
      }, `Отчеты по оплате ${this.$moment(new Date()).format('DD-MM-YYYY')}`);
    },
  },
}
</script>

<style lang="scss" scoped>

.pr-analytics h3 {
  color: #68C9E7;
}
</style>
